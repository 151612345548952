import { Button, Icon } from "@chakra-ui/react";
import { useNavigateWithParams } from "src/nav";
import { IoArrowBackSharp, IoArrowForwardSharp } from 'react-icons/io5';
import { RedirectToProps } from "src/pages/DetailPage";

interface INavigationButtonProps {
  redirectTo: RedirectToProps;
}

export const BackButton = ({ redirectTo }: INavigationButtonProps) => {
  const navigate = useNavigateWithParams();
  return <Button
    colorScheme='primary'
    variant='fill'
    onClick={() => navigate(redirectTo)}
  >
    <Icon as={IoArrowBackSharp} aria-hidden={true} />&nbsp;<span>Back</span>
  </Button>;
}

export const NextButton = ({ redirectTo }: INavigationButtonProps) => {
  const navigate = useNavigateWithParams();
  return <Button
    colorScheme='primary'
    variant='fill'
    onClick={() => navigate(redirectTo)}
  >
    <span>Next</span>&nbsp;<Icon as={IoArrowForwardSharp} aria-hidden={true} />
  </Button>;
}
