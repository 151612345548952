import React, { useCallback, useState } from "react";

import {
  Button,
  Card,
  CardFooter,
  Flex,
  Progress,
  Stack,
  StackProps,
  VStack,
} from "@chakra-ui/react";

import { BrandAssetDocument, LogoAssetDocument } from "src/db";
import FilePreview from "src/components/assets/FilePreview";
import { RemoveAssetIconButton } from "src/components/assets/actions";
import { useRemoveBrandAsset } from "src/components/assets/actions/hooks";

type HandleAsset = (assetDoc: LogoAssetDocument) => void;

interface VerticalAssetListProps extends StackProps {
  assets: BrandAssetDocument[];
  onAssetSelect?: HandleAsset;
  pendingSelections?: Set<string>;
}

export function VerticalAssetList({
  assets,
  onAssetSelect,
  pendingSelections = new Set(),
  ...stackProps
}: VerticalAssetListProps) {
  const [isRemovePending, setIsRemovePending] = useState<boolean>(false);

  const removeBrandAsset = useRemoveBrandAsset();

  const handleRemoveAsset = useCallback(
    (asset: BrandAssetDocument) => {
      if (isRemovePending) {
        return;
      }
      removeBrandAsset(asset, setIsRemovePending);
    },
    [isRemovePending, removeBrandAsset]
  );

  return (
    <Stack {...stackProps}>
      {assets.map((asset) => {
        return (
          <VStack align="center" key={asset.id}>
            <Card position="relative" bg={"grayscale.white"} mb={8} p={4}>
              <Flex justify="end" align="center" mb={2}>
                <RemoveAssetIconButton
                  onClick={() => handleRemoveAsset(asset)}
                />
              </Flex>
              <FilePreview file={asset.value.brand} width="300px" />
              <CardFooter>
                <VStack align="center" width="100%">
                  {isRemovePending && (
                    <Progress
                      size="md"
                      width="100%"
                      isIndeterminate={true}
                      my={4}
                    />
                  )}
                </VStack>
                <Button
                  as="a"
                  href={asset.value.brand.fileInfo.fileUrl}
                  target="_blank"
                  download={asset.value.brand.name}
                  colorScheme="primary"
                  variant="fill"
                  size={"sm"}
                  mx="auto"
                >
                  Download
                </Button>
              </CardFooter>
            </Card>
          </VStack>
        );
      })}
    </Stack>
  );
}
