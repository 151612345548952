import React, { SyntheticEvent, useCallback, useEffect, useRef } from 'react';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Image,
  Text,
  VStack,
  Progress,
  Modal,
  ModalOverlay,
  Textarea,
  IconButton,
  Box
} from '@chakra-ui/react';

import { Carousel } from 'src/components';
import { LogoPreview } from 'src/components/assets';
import { ConversationModalContent, ModalAction } from 'src/components/chat';

import {
  ActionTypes, ShareSocialMediaPostActionDocument, useActionCollection,
  AssetTypes, LogoAssetDocument, useAssetCollection,
  useBusinessName, useIsBrandKitLocked, useSelectedDate,
  useBusinessId
} from 'src/db';
import { SocialMediaPostPreview } from 'src/components/actions';
import { avatar } from 'src/images';
import { Path, SearchParam, useNavigateWithParams, useSearchParam } from 'src/nav';

import { CreateBusinessConversation } from './createBusiness/CreateBusinessConversation';
import { HeaderFooterPage } from './wrappers/HeaderFooterPage';
import { RequireBusinessOnly } from './wrappers/RequireBusiness';
import { useActiveThreadId, useExternalConversation } from 'src/components/chat/hooks';
import { useReportCollection } from 'src/db/hooks';
import { ReportDocument, ReportStatus, getShortSummary } from 'src/db/model/report';

export function BrandHubPage() {
  const businessName = useBusinessName();
  const [, setSelectedDate] = useSelectedDate();

  useEffect(() => {
    setSelectedDate(new Date());
  }, [setSelectedDate]);

  return (
    <HeaderFooterPage pageTitle='Brand Hub'>
      {businessName && <VStack direction='column' align='center' mx={4}>
        <Text textStyle='body' align='center' maxWidth='240px' mb={6}>
          {businessName}
        </Text>
      </VStack>}
      <RequireBusinessOnly OrElse={<CreateBusiness />}>
        <BrandHubPageContent />
      </RequireBusinessOnly>
    </HeaderFooterPage>
  );
}

function BrandHubPageContent() {
  const isBrandKitLocked = useIsBrandKitLocked();
  const navigate = useNavigateWithParams();
  const logos = useAssetCollection<LogoAssetDocument>(AssetTypes.logo);
  const posts = useActionCollection<ShareSocialMediaPostActionDocument>(ActionTypes.shareSocialMediaPostPreview);
  const reports = useReportCollection();
  const focusSection = useSearchParam(SearchParam.focusSection);
  const brandActionsSectionRef = useRef<HTMLDivElement>(null);
  const brandPrintsSectionRef = useRef<HTMLDivElement>(null);

  const selectedLogos = logos.filter((logo: LogoAssetDocument) => {
    return logo.selected;
  });
  const suggestedLogos = logos.filter((logo: LogoAssetDocument) => {
    return !logo.selected;
  });
  const previewLogos = !!selectedLogos.length ? selectedLogos : suggestedLogos;

  useEffect(() => {
    if (focusSection === 'brandActions') {
      brandActionsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (focusSection === 'brandPrints') {
      brandPrintsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  });

  // TODO: make this a Link
  const goToBrandkit = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    navigate({ to: Path.brandkit });
  }, [navigate]);

  // TODO: make this a Link
  const goToBrandActions = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    navigate({ to: Path.brandactions });
  }, [navigate]);

  // TODO: make this a Link
  const goToBrandReports = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    navigate({ to: Path.brandreports });
  }, [navigate]);

  return (
    <Box display="flex" flexDirection="column">
      <Card mb={4} p={2} >
        <BrandiSearch />

      </Card>
      <Card mb={12} p={6} onClick={goToBrandkit} order={isBrandKitLocked ? 6 : undefined}>
        <CardHeader mb={2}>
          <Text
            as='header'
            textStyle='titleForSection'
            align='center'
          >Brand Kit</Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align='center'>
            <Text
              textStyle='body'
              align='center'
              mb={2}
              pl={0.3}
              pr={0.3}
            >
              All the key elements of your brand in one place
            </Text>
            {previewLogos.length === 0 && <Progress
              isIndeterminate={true}
              width='50%'
              my={4} mx='auto'
            />
            }
            {!isBrandKitLocked && <Carousel itemWidth={200} itemSpacing={16} mb={2}>
              {previewLogos.map((logo, idx) => {
                return <LogoPreview
                  key='idx'
                  logo={logo}
                />
              })}
            </Carousel>}
          </VStack>
        </CardBody>
        <CardFooter>
          <Button
            colorScheme='primary'
            variant='fill'
            minWidth='200px'
            mx='auto'
            onClick={goToBrandkit}
          >Review</Button>
        </CardFooter>
      </Card>

      <Card mb={12} p={6} onClick={goToBrandActions}>
        <CardHeader mb={2}>
          <Text
            as='header'
            textStyle='titleForSection'
            align='center'
            mb={2}
          >Brand Actions</Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align='center'>
            <Text textStyle='body' align='center' mb={2} pl={0.3} pr={0.3}>
              Tailored-to-you actions to grow your brand weekly
            </Text>
            {posts.length === 0 && <Progress
              isIndeterminate={true}
              width='50%'
              my={4} mx='auto'
            />
            }
            <PostsCarousel posts={posts} />
          </VStack>
        </CardBody>
        <CardFooter>
        </CardFooter>
        <VStack align='center'>
          <Button
            colorScheme='primary'
            variant='fill'
            minWidth='200px'
            mx='auto'
            onClick={goToBrandActions}
          >See Actions</Button>
        </VStack>
      </Card>
      <Card mb={12} p={6} onClick={goToBrandReports}>
        <CardHeader mb={2}>
          <Text
            as='header'
            textStyle='titleForSection'
            align='center'
            mb={2}
          >Brand Reports</Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align='center'>
            <Text textStyle='body' align='center' mb={2} pl={0.3} pr={0.3}>
              Discover your next big thing
            </Text>
            <ReportsCarousel reports={reports} />
          </VStack>
        </CardBody>
        <CardFooter>
        </CardFooter>
        <VStack align='center'>
          {!!reports.filter(r => r.status === ReportStatus.READY).length ? <Button
            colorScheme='primary'
            variant='fill'
            minWidth='200px'
            mx='auto'
            onClick={goToBrandReports}
          >See Reports</Button>
            : reports.length === 0 &&
            <Button
              colorScheme='primary'
              variant='fill'
              minWidth='200px'
              mx='auto'>Generate a Report</Button>}
        </VStack>
      </Card>

      <Card mb={12} p={6}>
        <CardHeader mb={2}>
          <Text
            as='header'
            textStyle='titleForSection'
            align='center'
            mb={2}
          >Brand Creations</Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align='center'>
            <Text textStyle='body' align='center' mb={2} pl={0.3} pr={0.3}>
              <em>Coming Soon!</em>&nbsp;
              Boost your brand with Agent Brandi, your creative co-pilot to get you from concept to creations and campaigns.
            </Text>
          </VStack>
        </CardBody>
      </Card>
    </Box>
  );
}

function BrandiSearch() {
  const businessId = useBusinessId();
  const threadId = useActiveThreadId();
  if (!businessId || !threadId) {
    // Not an error, just means the user is not signed in.
    return <></>
  } else {
    return <BrandiSearchButton businessId={businessId} threadId={threadId} />;

  }
}
interface ChatButtonProps {
  businessId: string;
  threadId: string;
}

function BrandiSearchButton({ businessId, threadId }: ChatButtonProps) {
  const fabRef = useRef<HTMLButtonElement>(null);

  const conversation = useExternalConversation(businessId, threadId);
  return (<>
    <Textarea
      bg='grayscale.light'
      borderRadius='md'
      focusBorderColor='secondary.light'
      placeholder='Ask Agent Brandi...'
      resize='none'
      rows={1}
      onClick={() => conversation.toggleModal(true)}
    />
    <IconButton
      type='submit'
      variant='fill'
      position='absolute'
      bottom='12px'
      right='12px'
      boxSize='32px'
      icon={
        <Image
          src={avatar}
          alt='Talawa'
          boxSize="64px"
        />}
      aria-label='Send'
      isRound={true}
      onClick={() => conversation.toggleModal(true)}
    ></IconButton>
    <Modal
      onClose={() => { conversation.toggleModal(false); }}
      finalFocusRef={fabRef}
      isOpen={conversation.isModalOpen}
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ConversationModalContent />
    </Modal>
  </>)
}


function CreateBusiness() {
  const getStarted = useRef<HTMLButtonElement>(null);
  const toggleModal = useRef<ModalAction>(null);

  const openConversation = () => {
    !!toggleModal.current && toggleModal.current(true);
  }

  return (<VStack align='center'>
    <Text textStyle='direction' align='center' mb={2}>
      Tell Us About Your Business
    </Text>
    <CreateBusinessConversation
      finalFocusRef={getStarted}
      toggleModalRef={toggleModal}
      flex='1'
      mx={2}
    />
    <Button
      variant='fill'
      ref={getStarted}
      onClick={openConversation}
      width='100%'
      mb={4}
    >Get Started</Button>
  </VStack>);
}

function PostsCarousel({ posts }: { posts: ShareSocialMediaPostActionDocument[] }) {
  return (<Carousel itemWidth={200} itemSpacing={16} mb={2}>
    {posts.map((post, idx) => {
      return <Card key={idx} p={4}>
        <Text as='h4' textStyle='caption' align='left'>
          Share
        </Text>
        <SocialMediaPostPreview post={post} />
      </Card>
    })}
  </Carousel>);
}

function ReportsCarousel({ reports }: { reports: ReportDocument[] }) {
  return (<Carousel itemWidth={200} itemSpacing={16} mb={2} w='100%'>
    {reports.map((report, idx) => {

      return <Card key={idx} p={4} minHeight="150px" display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Text textStyle='caption' align='left'>
            {new Date(report.createdAt).toLocaleDateString()}
          </Text>
          <Text as='header' align='left' flexGrow={1}>
            {getShortSummary(report)}
          </Text>
        </Box>
        <Progress isIndeterminate={report.status === ReportStatus.CREATING} />
      </Card>
    })}
  </Carousel>);
}