import { useCallback } from 'react';

import { useApi } from 'src/api';
import { AssetDocument, BrandAssetDocument } from 'src/db';

export type MarkPending = (isPending: boolean) => void;
export type AssetActionRunner<Asset = AssetDocument> = (asset: Asset, markPending?: MarkPending) => Promise<any>;

export type BrandAssetActionRunner<Asset = BrandAssetDocument> = (asset: Asset, markPending?: MarkPending) => Promise<any>;

const markNothing = (isPending: boolean) => {};
const requestBase = (asset: AssetDocument) => {
  return {
    businessId: asset.businessId,
    assetType: asset.assetType,
    assetId: asset.id
  };
};

export function useSelectAsset(): AssetActionRunner {
  const [api, surfaceKnownErrors] = useApi();
  return useCallback((asset, markPending = markNothing) => {
    markPending(true);
    return api.selectAsset({
      ...requestBase(asset),
      selectedForBrandKit: true,
    }, surfaceKnownErrors).then(() => {
      markPending(false);
    });
  }, [api, surfaceKnownErrors]);
}

export function useUnselectAsset(): AssetActionRunner {
  const [api, surfaceKnownErrors] = useApi();
  return useCallback((asset, markPending = markNothing) => {
    markPending(true);
    return api.selectAsset({
      ...requestBase(asset),
      selectedForBrandKit: false,
    }, surfaceKnownErrors).then(() => {
      markPending(false);
    });
  }, [api, surfaceKnownErrors]);
}

export function useRemoveAsset(): AssetActionRunner {
  const [api, surfaceKnownErrors] = useApi();
  return useCallback((asset, markPending = markNothing) => {
    markPending(true);
    return api.reviewAsset({
      ...requestBase(asset),
      review: 'NEGATIVE',
    }, surfaceKnownErrors).then(() => {
      markPending(false);
    });
  }, [api, surfaceKnownErrors]);
}

export function useRemoveBrandAsset(): BrandAssetActionRunner {
  const [api, surfaceKnownErrors] = useApi();
  return useCallback((asset, markPending = markNothing) => {
    markPending(true);
    // Convert the object to FormData
    const formData = new FormData();
    formData.append('businessId', asset.businessId);
    formData.append('storageId', asset.value.brand.fileInfo.storageId);
    formData.append('collectionId', asset.id);
    
    return api.deleteBrandAsset(formData, surfaceKnownErrors).then(() => {
      markPending(false);
    });
  }, [api, surfaceKnownErrors]);
}