import { PropsWithChildren } from 'react';
import {
  Tab,
  Text,
} from '@chakra-ui/react';

const unSelectedTabStyle = {
  borderBottom: '1px solid',
  borderColor: 'grayscale.medium',
};

const selectedTabStyle = {
  bg: 'primary.veryLight',
  fontWeight: 'bold',
  borderBottom: '2px solid',
  borderColor: 'primary.veryDark',
};

const StyledTab = ({ children }: PropsWithChildren) => {
  return (<Tab
    sx={unSelectedTabStyle}
    _selected={selectedTabStyle}
  >
    <Text textStyle='buttonSmall' sx={{fontWeight: 'inherit'}}>{children}</Text>
  </Tab>);
}

export default StyledTab;
