import { createContext, SetStateAction } from 'react';
import { Firestore } from 'firebase/firestore';
import { 
  ProfileDocument,
  BusinessDocument,

  // Assets
  AudienceAssetDocument,
  ColorsAssetDocument,
  FontAssetDocument,
  LogoAssetDocument,
  NameAssetDocument,
  SloganAssetDocument,
  ValueAssetDocument,
  BrandStoryAssetDocument,

  // Actions
  ExploreEducationActionDocument,
  RegisterDomainActionDocument,
  ShareSocialMediaPostActionDocument,
  BrandAssetDocument,
} from 'src/db';
// Prevent circle dep for enums
import { ActionTypes  } from './model/action';
import { AssetTypes } from './model/asset';
import { ReportDocument } from './model/report';


export const FirestoreContext = createContext<Firestore>({} as Firestore);

export interface ProfileContextProperties {
  // May be available ahead of the profile itself, so pulled out for convenience
  profileId: string|null;
  
  // The full profile document.
  profile: ProfileDocument|null;
  
  // Whether the profile is loading.
  isLoading: boolean;

  // Credits available to the profile.
  credits: number|null; // Null if no profile OR still looking up.
}

export const ProfileContext = createContext<ProfileContextProperties>({
  profileId: null,
  profile: null,
  isLoading: false,
  credits: null, // Null if no profile OR still looking up.
});

export interface BusinessContextProperties {
  // May be available ahead of the business itself, so pulled out for convenience
  businessId: string|null;
  
  // The full business document.
  business: BusinessDocument|null;
  
  // Whether the business is loading.
  isLoading: boolean;
}

export const BusinessContext = createContext<BusinessContextProperties>({
  businessId: null,
  business: null,
  isLoading: false,
});

export interface ReportsContextProperties {
  collection: ReportDocument[];
  isLoading: boolean;
}

export const ReportsContext = createContext<ReportsContextProperties>({
  collection: [],
  isLoading: false,
});

export interface AssetContextProperties<DocumentType> {
  collection: DocumentType[];
  isLoading: boolean;
  error?: any;
  isComplete?: boolean;
}

export interface AssetsContextProperties {
  [AssetTypes.audience]: AssetContextProperties<AudienceAssetDocument>;
  [AssetTypes.color]: AssetContextProperties<ColorsAssetDocument>;
  [AssetTypes.font]: AssetContextProperties<FontAssetDocument>;
  [AssetTypes.logo]: AssetContextProperties<LogoAssetDocument>;
  [AssetTypes.brand]: AssetContextProperties<BrandAssetDocument>;
  [AssetTypes.name]: AssetContextProperties<NameAssetDocument>;
  [AssetTypes.slogan]: AssetContextProperties<SloganAssetDocument>;
  [AssetTypes.value]: AssetContextProperties<ValueAssetDocument>;
  [AssetTypes.brandStory]: AssetContextProperties<BrandStoryAssetDocument>;
};

const emptyAssetCollection = () : AssetContextProperties<any> =>  {
  return {
    collection: [],
    isLoading: false,
    error: null,
    isComplete: false,
  };
};

export const missingAssetsContext: AssetsContextProperties = {
  [AssetTypes.audience]: emptyAssetCollection(),
  [AssetTypes.color]: emptyAssetCollection(),
  [AssetTypes.font]: emptyAssetCollection(),
  [AssetTypes.logo]: emptyAssetCollection(),
  [AssetTypes.brand]: emptyAssetCollection(),
  [AssetTypes.name]: emptyAssetCollection(),
  [AssetTypes.slogan]: emptyAssetCollection(),
  [AssetTypes.value]: emptyAssetCollection(),
  [AssetTypes.brandStory]: emptyAssetCollection(),
};

export const AssetsContext = createContext<AssetsContextProperties>(missingAssetsContext);

export interface ActionContextProperties<DocumentType> {
  collection: DocumentType[];
  isLoading: boolean;
  error?: any;
}

export interface ActionsContextProperties {
  [ActionTypes.exploreEducation]: ActionContextProperties<ExploreEducationActionDocument>;
  [ActionTypes.registerDomain]: ActionContextProperties<RegisterDomainActionDocument>;
  [ActionTypes.shareSocialMediaPost]: ActionContextProperties<ShareSocialMediaPostActionDocument>;
  [ActionTypes.shareSocialMediaPostPreview]: ActionContextProperties<ShareSocialMediaPostActionDocument>;

  selectedDate: Date;
  setSelectedDate: (date: Date|SetStateAction<Date>) => void;
};

const emptyActionCollection = () : ActionContextProperties<any> => {
  return {
    collection: [],
    isLoading: false,
    error: null,
  };
};


export const unavailableActionContext: ActionsContextProperties = {
  [ActionTypes.exploreEducation]: emptyActionCollection(),
  [ActionTypes.registerDomain]: emptyActionCollection(),
  [ActionTypes.shareSocialMediaPost]: emptyActionCollection(),
  [ActionTypes.shareSocialMediaPostPreview]: emptyActionCollection(),
  // NoOp for selected date if unavailable.
  selectedDate: new Date(Date.now()),
  setSelectedDate: (date: Date|SetStateAction<Date>) => {},
};

export const unavailableReportsContext: ReportsContextProperties = {
  collection: [],
  isLoading: false,
};

export const ActionsContext = createContext<ActionsContextProperties>(unavailableActionContext);
