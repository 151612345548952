import React from 'react';

import { Center, Tag, TagLabel } from '@chakra-ui/react';

import { MAX_SELECTED_NAMES }  from 'src/api/constants';
import { AddNameAssetButton } from 'src/components/assets/addAsset';
import { AssetTypes, NameAssetDocument } from 'src/db';

import { AssetProps, DetailPage, RedirectToProps } from './DetailPage';
import { Path } from 'src/nav';

const redirectBack: RedirectToProps = {
  to: Path.assets,
};

const redirectTo: RedirectToProps = {
  to: Path.slogan,
};

export function NamePage() {
  return <DetailPage<NameAssetDocument>
    title='Name'
    assetUiName='Name'
    assetType={AssetTypes.name}
    SelectedAsset={SelectedName}
    SuggestedAsset={SuggestedName}
    selectionLimit={MAX_SELECTED_NAMES}
    AddAssetButton={AddNameAssetButton}
    redirectTo={redirectTo}
    redirectBack={redirectBack}
  />
}

function SelectedName({ asset }: AssetProps<NameAssetDocument>) {
  // TODO: Use a large size/variant for Tag
  return (<Center>
    <Tag key={asset.id} size="lg">
      <TagLabel sx={{display: 'inline-flex'}}>{asset.value.name}</TagLabel>
    </Tag>
  </Center>);
}

function SuggestedName({ asset }: AssetProps<NameAssetDocument>) {
  // TODO: Use a large size/variant for Tag
  return (<Center>
    <Tag key={asset.id} size="lg">
      <TagLabel sx={{display: 'inline-flex'}}>{asset.value.name}</TagLabel>
    </Tag>
  </Center>);
}
