import React, { SyntheticEvent, FormEvent, useRef, useState } from 'react';
import {
  Button,
  ButtonProps,
  Center,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { useApi } from 'src/api';
import { useBusinessId } from 'src/db';

export function AddBrandAssetButton({children, ...buttonProps}: ButtonProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isSubmitting) { return; }
    setIsSubmitting(true);
    const data = new FormData(e.target as HTMLFormElement);
    api.addBrandAsset(data, surfaceKnownErrors).then(() => {
      onClose();
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (<>
    <Button
      {...buttonProps}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
        onOpen();
      }}
    >{children}</Button>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      initialFocusRef={inputRef}
    >
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader>
          <Center>
            <Text textStyle='bodyLarge'>Add Assets</Text>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <input type='hidden' name='businessId' value={businessId} />
            <Flex
              direction = 'row'
              align='center'
              justify='space-between'
              wrap='wrap'
            >
              <input ref={inputRef} type='file' name='files' multiple />
              <Spacer flex='0 0 8px' />
              <Button
                type='submit'
                variant='fill'
                colorScheme='primary'
                size='sm'
                flex='0 0 fit-content'
                isLoading={isSubmitting}
              >Add</Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  </>);
}
