import React from "react";

import { BoxProps } from "@chakra-ui/react";

import { AssetTypes, BrandAssetDocument, brandAssetConverter } from "src/db";
import { Carousel } from "src/components";
import { Path } from "src/nav";

import { AssetsProps, BrandKitCard } from "./BrandKitCard";
import { AddBrandAssetButton } from "src/components/assets/addAsset/AddBrandAsset";
import FilePreview from "src/components/assets/FilePreview";

const AssetsCard = ({ ...boxProps }: BoxProps) => {
  const SuggestedBrandAssets = ({
    assets,
  }: AssetsProps<BrandAssetDocument>) => {
    return (
      <Carousel itemWidth={100} itemSpacing={16}>
        {assets.map((asset) => {
          return <FilePreview key={asset.id} file={asset.value.brand} />;
        })}
      </Carousel>
    );
  };

  const SelectedBrandAssets = SuggestedBrandAssets;

  return (
    <BrandKitCard<BrandAssetDocument>
      title="Assets"
      assetType={AssetTypes.brand}
      firestoreConverter={brandAssetConverter}
      reviewPath={Path.assets}
      SelectedAssets={SelectedBrandAssets}
      SuggestedAssets={SuggestedBrandAssets}
      AddAssetButton={AddBrandAssetButton}
      showSuggestMore={false}
      {...boxProps}
    />
  );
};

export default AssetsCard;
