import { Box, Image, Link, Text } from "@chakra-ui/react";

type File = {
  name: string;
  fileInfo: {
    fileUrl: string;
  };
};

interface FilePreviewProps {
  file: File;
  width?: string;
}

const FilePreview = ({ file, width = "100px" }: FilePreviewProps) => (
  <Link href={file.fileInfo.fileUrl} target="_blank">
    <Box mb={4}>
      {file.name?.match(/[^/]+(jpg|jpeg|jfif|pjpeg|pjp|png|gif|svg)$/) ? (
        <Image
          src={file.fileInfo.fileUrl}
          alt={file.name}
          boxSize={width}
          objectFit="cover"
          borderRadius="md"
        />
      ) : (
        <Box
          w={width}
          h="100px"
          borderRadius="md"
          borderWidth="1px"
          borderColor="gray.200"
          textAlign={"center"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="sm"
          color="gray.600"
        >
          <Text>{file.name}</Text>
        </Box>
      )}
    </Box>
  </Link>
);

export default FilePreview;
